/* global $ */
/* global WebViewGootChannel */
import React, {Component} from 'react';
import LazyLoad from 'react-lazyload';

class CompanyRow extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    getClass(metric, thresholds) {
        metric = parseInt(metric)
        const { success, warning, danger } = thresholds

        if (metric === 0) return ''
        if (metric < success) return 'bg-success'
        if (metric >= danger) return 'bg-danger'
        if (metric < warning) return 'bg-warning'
        return ''
    }

    componentDidMount() {
        this.attachWebviewCallback();
    }

    componentDidUpdate() {
        this.attachWebviewCallback();
    }

    attachWebviewCallback() {
        $(document).off('click', '[data-callback="webview"]') // remove previous event listeners
        $(document).on('click', '[data-callback="webview"]', function () {
            var data = { redirect: true, where: $(this).attr('data-type'), args: { id: $(this).attr('data-id') } };
            console.log(data);

            if (typeof WebViewGootChannel !== 'undefined') {
                WebViewGootChannel.postMessage(JSON.stringify(data));
            }
        });
    }

    
    render() {
        const oldestDueDate = CompanyRow.getDueDays(this.props.oldestDueDate)
        const thresholds = { 
            ca:         { success: 0, warning: 0, danger: 30 },
            echus:      { success: 0, warning: 1000, danger: 1000 },
            nonEchus:   { success: 0, warning: 5000, danger: 5000 },
            retard:     { success: 0, warning: 30, danger: 30 }
        }

        return (
            <LazyLoad height={150} once offset={15000}>
                <div className="company-row" data-reference={this.props.referenceERP} data-reference2={this.props.referenceERP2} data-id={this.props.id}
                     data-callback="webview"
                     data-type="outstanding"
                     data-warehouse={this.props.warehouse}
                     data-salesmanerp={this.props.salesmanERP}
                >
                    <div className="company-title">
                        <div className="company-status" style={{ background: this.props.color || '' }}>&nbsp;</div>
                        <div className="company-referenceERP">{this.props.referenceERP2 || this.props.referenceERP}</div> -&nbsp;
                        <div className="company-name">{this.props.name}</div>
                    </div>
                    <div className="company-balance col-12">
                        <div className="row">
                            <div className={'company-balance-row col-3 ' + this.getClass(this.props.ca, thresholds.ca)}>
                                <div className="company-ca">{this.props.ca || 0} j</div>
                                <div>CA</div>
                            </div>
                            <div className={'company-balance-row col-3 ' + this.getClass(this.props.encoursEchu, thresholds.echus)}>
                                <div className="company-encoursEchu">{this.props.encoursEchu || 0} {this.props.currency}</div>
                                <div>echus</div>
                            </div>
                            <div className={'company-balance-row col-3 ' + this.getClass(this.props.encoursNonEchu, thresholds.nonEchus)}>
                                <div className="company-encoursNonEchu">{this.props.encoursNonEchu || 0} {this.props.currency}</div>
                                <div>non echus</div>
                            </div>
                            <div className={'company-balance-row col-3 ' + this.getClass(oldestDueDate, thresholds.retard)}>
                                <div>{oldestDueDate} j</div>
                                <div>{oldestDueDate > 1 ? 'retards' : 'retard'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </LazyLoad>
        );
    }

    static getDueDays(oldestDueDate) {
        if (!oldestDueDate) return 0;
        let date1 = new Date(oldestDueDate);
        let date2 = new Date();
        let difference = date1.getTime() - date2.getTime();
        const DAY_IN_SECONDS = 3600 * 24
        return Math.abs(Math.ceil(difference / (1000 * DAY_IN_SECONDS)));
    }
}

export default CompanyRow;
